.border_color_gray {
  border: 1px solid #edf4f9;
}
/* .border_color_blue{
    border: 2px solid #1D72DB;
} */
.text_color_lightblue {
  color: #1d72db;
}
/* .hr_property{
    margin: 0.5rem 0;
    color: inherit;
    border: 0;
    border-top:2px solid #EDF4F9;
    opacity: 2.25;
} */
/* .btn_width{
    width: 40%;
} */
.dashboard_cards {
  max-width: 16% !important;
  height: 180px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: #0f203c;
  opacity: 1;
  display: block;
  background: url(../assets/icons/date-icon.svg) no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
}

.request_call_date_input {
  border: 1px solid #dbe9f2;
  border-radius: 7px;
}
.request_call_date_input_error {
  border: 1px solid red;
  border-radius: 7px;
}
@media only screen and (max-width: 568px) {
  .dashboard_cards {
    max-width: 14rem !important;
    height: 130px;
  }
}