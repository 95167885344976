.loaction_search_input {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  /* position: absolute;
  top: 23px;
  left: 8px;
  z-index: 999; */
  z-index: 999;
}
.loaction_search_input_error {
  border: 1px solid red;
  border-radius: 5px;
}
.loaction_search_input::placeholder {
  color: #bcc7ce;
  font-size: 13px;
}
.cancel_search_icon {
  position: absolute;
  top: 4px;
  /* top: 27px; */
  /* /* right: 0.5rem; */
  z-index: 999;
  right: 0.2rem;
}
.search_list {
  position: absolute;
  z-index: 999;
  background-color: white;
  border-radius: 5px;
  /* top: 50px; */
  left: 1.5rem;
}
.map_suggestions:hover {
  background-color: rgb(80, 191, 151, 0.5);
  border-radius: 3px;
}

/* edit  */

.map_container_edit {
  width: 100%;
  height: 15rem;
}

.address_block_edit_property {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  box-shadow: 0px 3px 23px 0px #68728433;
  width: 20rem;
}

.edit_location_modal {
  max-width: 45rem;
}
.component_design {
  box-shadow: 0px 0px 15px rgba(50, 61, 90, 0.07);
  border-radius: 15px;
  background-color: #ffffff;

  /* width: 100%; */
}
@media only screen and (max-width: 576px) {
  .address_block_edit_property {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    box-shadow: 0px 3px 23px 0px #68728433;
    width: fit-content;
    margin-right: 10px;
  }
}

.post_property_map_container {
  max-width: 1100px;
}

.post_property_form_container {
  max-width: 550px;
}

.map_container {
  max-width: 55rem;
  width: 100%;
  height: 325px;
  border-radius: 5px;
}

.red_colorrrrr {
  color: red;
}

.marker {
  width: 20px;
  height: 20px;
  background-image: url("../../assets//images/Vector.png");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.post_property_form_container {
  position: absolute;
  top: 30px;
  left: 0.5rem;
  z-index: 999;
  left: 0.5rem;
}
.in_map_address {
  z-index: 999;
  color: black;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 69%;
  transform: translate(-50%, -100%);
}
