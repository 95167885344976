.comp_input {
  border: 1px solid #bcc7ce;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
}
.comp_input__error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
}
.comp_input::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 20%;
  font-weight: 300;
  font-size: 13px;
}
.comp_input__container {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 0px 10px;
}
.comp_input_container__error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 0px 10px;
}

/* textarea  */
.comp_textarea_input {
  border: 1px solid #bcc7ce;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
  height: 6rem;
  resize: none;
}
.comp_textarea_input__error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
  height: 6rem;
}
.comp_textarea_input::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 10%;
  font-weight: 300;
  font-size: 13px;
}
.comp_textarea_input__container {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 0px 10px;
}
.comp_textarea_input_container__error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 0px 10px;
}

/* useFormik  */
.text_area_input {
  padding: 6px 10px;
  font-weight: 400;
  height: 6rem;
}
.text_area_input::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 10%;
  font-weight: 300;
  font-size: 13px;
}

.input_text::placeholder {
  color: var(--light-gray);
  font-weight: 300;
  font-size: 13px;
}
.error_height {
  height: 15px;
}

/* modals  */

.saved_modal {
  max-width: 30rem;
}
.billing_saved_modal {
  max-width: 24rem;
}
.logout_modal {
  max-width: 25rem;
}

/* select Top Location Model */
.select_location {
  max-width: 65rem;
}
.active_location_img {
  border: 2px solid #1d72db;
  border-radius: 7px;
}
.inActive_location_img {
  border: 1px solid #dbe9f2;
  border-radius: 7px;
}
.mobile_view_location{
  width: 9rem;
}
.submit_btn{
  background-color: #1D72DB;
  border-radius: 7px;
}
.search_loc_input{
  border: 1px solid #DBE9F2;
  border-radius: 7px;
}
.search_loc_input::placeholder{
  padding-left: 5px;
  color: #BCC7CE;
  font-size: 14px;
}

.range-slider {
  position: relative;
  height: 16px;
  border-radius: 10px;
  background-color: #4b4d61;
}

/* AB Range Slider */
/* AB Range Slider */



@media only screen and (max-width: 992px) {
  .active_location_img {
    border: 2px solid #1d72db;
    border-radius: 7px;
    width: 11rem;
  }
  .inActive_location_img {
    border: 1px solid #dbe9f2;
    border-radius: 7px;
    width: 11rem;
  }
}
@media only screen and (max-width: 475px) {
  .active_location_img {
    border: 2px solid #1d72db;
    border-radius: 7px;
    width: 9.5rem;
  }
  .inActive_location_img {
    border: 1px solid #dbe9f2;
    border-radius: 7px;
    width: 9.5rem;
  }
}
/* select Top Location Model */
.no_btn {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
}

@media only screen and (max-width: 576px) {
  .comp_input::placeholder {
    top: 23%;
  }
  .no_btn {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
