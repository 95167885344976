.postprop_input {
    border: 1px solid #dbe9f2;
    border-radius: 5px;
    padding: 6px 10px;
    font-weight: 500;
  }
  .postprop_input::placeholder {
    color: var(--light-gray);
    position: absolute;
    top: 20%;
    font-weight: 300;
    font-size: 13px;
  }
  .property_feature_input_container {
    max-width: 264px;
  }
  .property_feature_radiobox_container {
    max-width: 264px;
  }
  @media only screen and (max-width: 578px) {
    .postprop_input {
      border: 1px solid #dbe9f2;
      border-radius: 5px;
      padding: 6px 10px;
      font-weight: 500;
      width: 100%;
    }
    .property_feature_input_container {
      max-width: 100%;
    }
  }
/********CheckBox*********/
.selectpaymeth_checkbox_label {
  width: fit-content;
  position: relative;
  padding-left: 18px;
  margin-bottom: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.selectpaymeth_checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.selectpaymeth_checkbox_span {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 1.1rem;
  width: 1.1rem;
  border: 2px solid var(--light-gray);
  border-radius: 1px;
}
/* When the checkbox is checked, add a blue background */
.selectpaymeth_checkbox_label input:checked ~ .selectpaymeth_checkbox_span {
  background-color: var(--primary-color);
  border: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.selectpaymeth_checkbox_span:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 1.8px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Show the checkmark when checked */
.selectpaymeth_checkbox_label
  input:checked
  ~ .selectpaymeth_checkbox_span:after {
  display: block;
}
/*****************/