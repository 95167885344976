.scroll_decoration::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

.scroll_decoration::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll_decoration::-webkit-scrollbar-thumb {
  background-color: rgb(220, 225, 227);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
