body {
  font-family: "Poppins", sans-serif !important;
  color: rgba(50, 61, 90, 1) !important ;
}
:root {
  --primary-color: rgb(29, 114, 219);
  --secondary-color: #323d5a;
  --yellow-color: #fbc352;
  --light-green: #50bf97;
  --dark-green: #24734e;
  --light-gray: #bcc7ce;
}
.disabled_button:disabled {
  cursor: no-drop;
}
.main_scrollbar::-webkit-scrollbar {
  display: none;
}
.font_family_poppins {
  font-family: "Poppins", sans-serif;
}
.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fs_14 {
  font-size: 14px;
}
.fs_18 {
  font-size: 18px;
}
.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 16px;
}
.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px;
}
.fs_22 {
  font-size: 22px;
}
.fs_15 {
  font-size: 17px;
}
.fs_24 {
  font-size: 24px;
}
.fs_28 {
  font-size: 28px;
}
.fs_30 {
  font-size: 30px;
}
.fs_75 {
  font-size: 75px;
}
.color_323D5A {
  color: #323d5a;
}
.text_color_blue {
  color: rgba(29, 114, 219, 1);
}
.text_color_grey {
  color: rgba(172, 187, 197, 1);
}
.text_color_darkblue {
  color: rgba(50, 61, 90, 1);
}
.text_color_light_green {
  color: rgba(80, 191, 151, 1);
}
.background_color_red {
  background-color: rgba(217, 28, 48, 1);
}
.background_color_skyblue {
  background-color: rgba(29, 114, 219, 1);
}
.background_color_green {
  background-color: rgba(80, 191, 151, 1);
}
.background_color_light_grey {
  background-color: rgba(237, 244, 249, 1);
}
.bg_transparent_color{
  background-color: transparent;
}
.table_background_color_grey {
  background-color: #fafafa;
}
.border_color {
  border: 1px solid rgba(219, 233, 242, 1);
}
.cursor_pointer {
  cursor: pointer;
}
.main_layout_container {
  width: calc(100% - 250px);
}
.sidebar_container {
  max-width: 400px;
  min-width: 290px;
  border-right: 1px solid #e5ecff;
}
.border_color_grey {
  border: 1px solid rgba(219, 233, 242, 1);
}
.border_1px_E5ECFF{
  border: 1px solid #E5ECFF;
}
.border_color_red {
  border: 1px solid rgba(217, 28, 48, 1);
}
.zameen_square_logo_height {
  height: 38px;
}
.pagination_text_color {
  color: #50bf97;
}
.pagination_background_color {
  background-color: #edf4f9;
}
.white_color {
  color: #ffffff;
}

.horizontal_line_color {
  color: #dbe9f2;
}

.horizontal_line {
  border: 2px solid #689fc1;
}

.horizontal_line_1px {
  border: 1px solid #edf4f9;
}

.border_primary {
  border: 1px solid var(--light-gray);
}

.white_border {
  border: 2px solid #ffffff;
}

/* border color */
.input_border {
  border: 1px solid #dbe9f2;
}
.input_bottom_border {
  border-bottom: 1px solid #dbe9f2;
}

.error_border {
  border: 1px solid red;
}

.delete_button_border {
  border: 2px solid #d91c30;
}
.color_D91C30{
  color: #D91C30;
}
.border_none {
  border: none;
}
.border_pale_blue {
  border: 2px solid#EDF4F9;
}

.border_light_blue {
  border: 1px solid #1d72db;
}
.border_dark_blue {
  border: 3px solid #1d72db;
}

/* border radius  */
.border_radius_5px {
  border-radius: 5px;
}
.border_radius_7px {
  border-radius: 7px;
}
.border_radius_10px {
  border-radius: 10px;
}

.border_bottom_13px {
  border-radius: 13px;
}

.border_radius_15 {
  border-radius: 15px;
}

.border_radius_20 {
  border-radius: 20px !important;
}

.border_radius_7 {
  border-radius: 7px;
}

.border_radius_4 {
  border-radius: 4px;
}
.border_blue_color {
  border: 1px solid #1d72db;
}
.border_bottom_one_px {
  border-bottom: 1px solid #edf4f9;
}
.focus_none:focus-visible {
  outline: none;
}
.secondary_color {
  color: var(--secondary-color);
}
.red_color {
  color: red;
}
.light_blue_color {
  color: #1d72db;
}
.light_green_color {
  color: var(--light-green);
}
.text_muted_color {
  color: #acbbc5;
}
.scrolling::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.green_bg {
  background-color: #50bf97;
}
.custom-date-picker::-webkit-calendar-picker-indicator {
  background-image: url("../src/assets/icons/date-icon.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  /* opacity: 0.5; */
  cursor: pointer;
}

.custom-date-picker::-webkit-inner-spin-button {
  display: none;
}
@media only screen and (max-width: 992px) {
  .fs_md_12 {
    font-size: 12px;
  }

  .fs_md_14 {
    font-size: 14px;
  }

  .fs_md_16 {
    font-size: 16px;
  }

  .fs_md_18 {
    font-size: 18px;
  }

  .fs_md_20 {
    font-size: 20px;
  }

  .fs_md_24 {
    font-size: 24px;
  }

  .fs_md_28 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .fs_lg_12 {
    font-size: 12px;
  }

  .fs_lg_14 {
    font-size: 14px;
  }

  .fs_lg_16 {
    font-size: 16px;
  }

  .fs_lg_18 {
    font-size: 18px;
  }

  .fs_lg_20 {
    font-size: 20px;
  }

  .fs_lg_24 {
    font-size: 24px;
  }

  .fs_lg_28 {
    font-size: 28px;
  }
  .text_sm_wrap {
    white-space: normal !important;
  }
  .width_xl_100 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .fs_sm_10 {
    font-size: 10px;
  }

  .fs_sm_12 {
    font-size: 12px;
  }

  .fs_sm_14 {
    font-size: 14px;
  }

  .fs_sm_16 {
    font-size: 16px;
  }

  .fs_sm_18 {
    font-size: 18px;
  }

  .fs_sm_20 {
    font-size: 20px;
  }

  .fs_sm_24 {
    font-size: 24px;
  }

  .fs_sm_28 {
    font-size: 28px;
  }

  .fw_sm_500 {
    font-weight: 500;
  }
}
@media only screen and (min-width: 768px) {
  .fit_sm_content {
    width: fit-content;
  }
}

@media only screen and (max-width: 576px) {
  .fs_xs_17 {
    font-size: 17px;
  }

  .fs_xs_10 {
    font-size: 10px;
  }

  .fs_xs_11 {
    font-size: 11px;
  }

  .fs_xs_12 {
    font-size: 12px;
  }

  .fs_xs_14 {
    font-size: 14px;
  }

  .fs_xs_16 {
    font-size: 16px;
  }

  .fs_xs_18 {
    font-size: 18px;
  }

  .fs_xs_20 {
    font-size: 20px;
  }
  .fw_xs_500 {
    font-weight: 500;
  }
  .blue_sm_color {
    color: #013ea3;
  }

  .light_blue_color {
    color: #1d72db;
  }
}

@media only screen and (max-width: 568px) {
  .zameen_square_logo_height {
    height: 30px;
  }
  .fs_sm_22 {
    font-size: 22px;
  }
  .empty_table_position {
    left: 25% !important ;
  }
}
