.post_property_container {
  max-width: 1200px;
}
.post_property_description_container {
  max-width: 790px;
}
.post_property_form_container {
  max-width: 550px;
}
.post_property_submit_btn {
  border: 1px solid #1d72db;
  background-color: #1d72db;
  border-radius: 7px;
}
.post_property_map_container {
  max-width: 1100px;
}
.property_feature_container {
  max-width: 850px;
}
.property_feature_input_container {
  max-width: 264px;
}
.price_details_input_container {
  max-width: 320px;
}
/****MODALS******/
.usertype_modal__btn {
  border: 2px solid #edf4f9;
  padding: 10px;
}
.usertype_modal__container {
  max-width: 500px;
  margin-inline: auto;
}
.builderreg_modal__container {
  max-width: 750px;
  margin-inline: auto;
}
.builderreg_modal_upload__contain {
  width: 80px;
  height: 80px;
}
.proptype_sell__text {
  top: 80%;
  left: 50%;
}
.proptype_sell__active {
  outline: 2px solid var(--primary-color);
  width: 180px;
  height: 180px;
}
.proptype_sell__inactive {
  outline: 1px solid #dbe9f2;
  width: 180px;
  height: 180px;
}

.select_heading {
  background-color: #edf4f9;
  color: #1d72db;
}
.editPropertyImageModalContainer {
  max-width: 51.5rem;
  max-height: 21rem;
}
.edit_property_modal_body {
  min-height: 21rem;
}
.edit_property_image_delete {
  top: -5px;
  left: 70px;
}
.images_container {
  min-height: 16rem;
}
.input_search_field {
  color: #323d5a;
  font-size: 14px;
  border-radius: 7px;
}
.input_search_field::placeholder {
  color: #BCC7CE;
  font-size: 14px;
}
.input__container {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 7px 10px;
}
.input_container__error {
  border: 1px solid red;
  border-radius: 5px;
  padding: 7px 10px;
}
.select_locality_dropdown{
  top: 3rem;
  left: 0rem;
  background-color: white;
  width: 100%;
  /* height: 12rem; */
  z-index: 5;
  overflow-y: auto;
  box-shadow: 0px 0px 15px #68728433;
  /* max-width: 50rem; */
}
/**************/
/******STEPPER********/
/**************/
.postprop_stepper_main__contain {
  width: fit-content;
  min-height: 40rem;
}
.postprop_stepper__contain {
  min-height: 100px;
  border-radius: 14px;
  padding: 95px 50px 1px 50px;
}
.postprop_stepper_line_cont {
  /* padding: 0 0 8px; */
  margin-left: 9px;
}
.postprop_stepper_line {
  min-height: 50px;
  border-left-style: solid;
  border-left-width: 2px;
  display: block;
  border-color: #ffff;
}
.postprop_opac {
  opacity: 0.2;
}
/******Registeration Paged*******/
.postprop__contain {
  box-shadow: 0px 3px 23px 0px #1d72db0d;
  border-radius: 0px 14px 14px 0px;
  min-width: 700px;
  max-width: 700px;
  padding: 3.8rem;
}
.postprop_next__btn {
  width: 130px;
  padding: 10px 17px;
}
.postprop_next__btn:disabled {
  background-color: rgb(29, 144, 219, 0.6);
}
.postprop_input {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
}
.postprop_input::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 20%;
  font-weight: 300;
  font-size: 13px;
}
.postProjDate {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
  color: #323d5a;
  font-size: 16px;
}

.postProjDate::placeholder {
  color: #acbbc5;
  position: absolute;
  top: 20%;
  font-weight: 500;
  font-size: 15px;
}
.property_details_input {
  border: 1px solid #dbe9f2;
  border-radius: 5px;
  padding: 6px 10px;
  font-weight: 500;
}
.property_details_input::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 20%;
  font-weight: 300;
  font-size: 13px;
}
/********CheckBox*********/
.selectpaymeth_checkbox_label {
  width: fit-content;
  position: relative;
  padding-left: 18px;
  margin-bottom: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.selectpaymeth_checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.selectpaymeth_checkbox_span {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 1.1rem;
  width: 1.1rem;
  border: 2px solid var(--light-gray);
  border-radius: 1px;
}
/* When the checkbox is checked, add a blue background */
.selectpaymeth_checkbox_label input:checked ~ .selectpaymeth_checkbox_span {
  background-color: var(--primary-color);
  border: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.selectpaymeth_checkbox_span:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 1.8px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Show the checkmark when checked */
.selectpaymeth_checkbox_label
  input:checked
  ~ .selectpaymeth_checkbox_span:after {
  display: block;
}
/*****************/

/* map  */
.map_container {
  /* max-width: 38rem; */
  max-width: 100%;
  height: 18rem;
  border-radius: 5px;
}

.comboboxInput {
  border: 1px solid #dbe9f2;
  width: 100%;
  border-radius: 5px;
}
.comboboxInput:focus-visible {
  outline: none;
}
.comboboxInput::placeholder {
  color: #bcc7ce;
}
/* ********  */

/* agriculture  */
.address_text_area {
  height: 6rem;
  border: 1px solid #dbe9f2;
  border-radius: 5px;
}
.address_text_area::placeholder {
  color: var(--light-gray);
  position: absolute;
  top: 5px;
  font-weight: 300;
  font-size: 13px;
}
/* ******  */
.marker {
  width: 30px;
  height: 30px;
  /* background-image: url("../../assets//icons/marker.png.png"); */
  background-image: url("../../assets/icons/Path_icon\(search\).svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

/*****Feature Variation Box*****/
.featurevar__container {
  height: 31.5rem;
  overflow-y: auto;
}

@media only screen and (max-width: 992px) {
  .postprop_stepper_main__contain {
    min-height: fit-content;
  }
  .map_container {
    height: 400px;
  }
  .postprop__contain {
    min-width: 500px;
    border-radius: 0px 0px 8px 8px;
  }
  .postprop_stepper__contain {
    padding: 1px 40px;
  }
  .postprop_stepper__contain {
    border-radius: 8px 8px 0px 0px;
  }
  .postprop_stepper__text {
    font-size: 11px !important;
    position: absolute;
    text-align: center;
  }
  .postprop_stepper_line {
    border-top-width: 2px;
    min-width: 50px;
    min-height: 5px;
    border-top-style: solid;
    border-left-width: 0px;
    display: block;
    border-color: #ffff;
  }
  .postprop_stepper_line_cont {
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .postprop__contain {
    padding: 2rem;
  }
}
@media only screen and (max-width: 578px) {
  .postprop_input {
    border: 1px solid #dbe9f2;
    border-radius: 5px;
    padding: 6px 10px;
    font-weight: 500;
    width: 100%;
  }
  .property_feature_input_container {
    max-width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .postprop_stepper__contain {
    border-radius: 0px;
  }
  .postprop__contain {
    min-width: 100vw;
  }
  .postprop_stepper__contain {
    padding: 1px 30px;
  }
  .proptype_sell__active {
    width: 110px;
    height: 110px;
  }
  .proptype_sell__inactive {
    width: 110px;
    height: 110px;
  }
  .edit_property_image_delete {
    top: -5px;
    left: 35px;
  }
}
