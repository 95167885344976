.locationIcon {
  left: 12px;
  top: 10px;
}
.homeIcon {
  left: 12px;
  top: 7px;
}
.contentContainer {
  max-width: 60rem;
}
.contentAdsContainer {
  max-width: 65rem;
}
.input_design {
  background-color: #ffffff;
  border: 1px solid #dbe9f2;
  border-radius: 7px;
  position: absolute;
}

.disabled_input_design {
  background-color: #f1f1f1;
  border: 1px solid #dbe9f2;
  border-radius: 7px;
  position: absolute;
  /* position: absolute; */
}
.home_input_design {
  border: 1px solid #dbe9f2;
  border-radius: 7px;
  position: relative;
}
/* .search_container {
    overflow-x: scroll;
  } */
.ad_label_container {
  position: absolute;
  right: 0;
  background-color: rgb(0, 0, 0, 0.2);
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.select_dropdown_content {
  top: 3rem;
  width: 6.4rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
  z-index: 1;
}

.search_city_input {
  min-width: 18rem;
}
.search_city_input::placeholder {
  color: #bcc7ce;
  font-size: 12px;
}
.search_loc_input_after_search {
  background-color: #ffffff;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  position: relative;
}
.search_loc_input_before_search {
  background-color: #ffffff;
  border-radius: 7px;
  position: relative;
}
.search_loc {
  position: relative;
}
.dropdown_search_field {
  border: 1px solid black;
  border-radius: 7px;
}
.dropdown_search_field::placeholder {
  color: black;
  font-size: 12px;
}
.search_localities_dropdown_content {
  overflow-y: auto;
  height: 22rem;
  /* top: 2rem;
    width: 20.4rem;
    right: -0.5rem; */
  top: 2rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: white;
  z-index: 10;
}
.search_localities_dropdown_content::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}
.search_localities_dropdown_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.search_localities_dropdown_content::-webkit-scrollbar-thumb {
  background: #bec4c4;
}
.search_localities_dropdown_content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* .search_loc_input_mobile {
    top: 2.3rem;
    z-index: 10;
    background-color: white;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  } */

.property_type_input_width {
  width: 10rem;
}
.pg_search_screen_input_width {
  width: 18rem;
}
.property_type_dropdown_content {
  top: 3rem;
  width: 27rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 10;
}

.property_type_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 12.5rem;
  margin-top: -10px;
}

.home_property_type_dropdown_content {
  top: 4rem;
  width: 27rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  z-index: 10;
}

.home_property_type_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 12.5rem;
  margin-top: -10px;
}

.button_design {
  border: 1px solid #acbbc5;
  border-radius: 5px;
  width: fit-content;
  background-color: white;
}
.clickOn_button_after {
  border: none;
  background-color: #1d72db;
  width: fit-content;
  border-radius: 5px;
}

.budget_dropdown_content {
  top: 3rem;
  width: 18rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
  z-index: 1;
}
.budget_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 7.9rem;
  margin-top: -10px;
}
.home_budget_dropdown_content {
  top: 3.5rem;
  width: 18rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
  z-index: 1;
}
.home_budget_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 7.9rem;
  margin-top: -10px;
}

.bedroom_input_width {
  width: 8rem;
}
.bedroom_dropdown_content {
  top: 3rem;
  width: 22rem;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
}
.bedroom_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 10rem;
  margin-top: -10px;
}
.postedby_input_data {
  background-color: #edf4f9;
  border-radius: 5px;
  color: #323d5a;
}
.filter_dropdown_content {
  top: 3rem;
  width: 25rem;
  height: fit-content;
  right: 0rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
}
.filter_dropdown_content:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 0;
  border-bottom: 13px solid white;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  bottom: 100%;
  left: 48%;
  margin-left: 21.9rem;
  margin-top: -10px;
}
.filter_overflow_y_scroll {
  overflow-y: scroll;
  height: 100%;
  max-height: 26rem;
}
.filter_overflow_y_scroll::-webkit-scrollbar {
  width: 0px;
}
.filter_overflow_y_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}
.filter_overflow_y_scroll::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
.more_filter_width {
  width: 5rem;
}

/* //////////////// */

.search_content_container {
  /* height: 90vh;
    overflow-y: auto; */
  min-height: 50vh;
}
.search_content_container::-webkit-scrollbar {
  width: 3px;
}
.search_content_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}
.search_content_container::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.filter_btn {
  background: #ffffff;
  border: 1.5px solid #1d72db;
  border-radius: 7px;
  color: #1d72db;
  text-transform: uppercase;
}
.filter_submit_btn {
  background: #1d72db;
  border-radius: 7px;
  color: #ffffff;
  text-transform: uppercase;
}
.filter_modal_Container {
  top: 4rem !important;
  width: 100% !important;
}

.filter_modal_sub_container {
  height: 50rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.active_btn {
  background: #1d72db;
  border-radius: 5px;
  color: #ffffff;
}

.non_active_btn {
  background: #ffffff;
  border: 1px solid #bcc7ce;
  border-radius: 5px;
}
.searchmain_relative__container {
  position: sticky;
  background-color: #edf4f9;
  z-index: 10;
  top: 3.5rem;
}

/********CheckBox*********/
.selectpaymeth_checkbox_label {
  width: fit-content;
  position: relative;
  padding-left: 18px;
  margin-bottom: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.selectpaymeth_checkbox_label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.selectpaymeth_checkbox_span {
  position: absolute;
  top: 0.2rem;
  left: 0;
  height: 1.1rem;
  width: 1.1rem;
  border: 2px solid var(--light-gray);
  border-radius: 1px;
}
/* When the checkbox is checked, add a blue background */
.selectpaymeth_checkbox_label input:checked ~ .selectpaymeth_checkbox_span {
  background-color: var(--primary-color);
  border: none;
}
/* Create the checkmark/indicator (hidden when not checked) */
.selectpaymeth_checkbox_span:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 1.8px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Show the checkmark when checked */
.selectpaymeth_checkbox_label
  input:checked
  ~ .selectpaymeth_checkbox_span:after {
  display: block;
}
/*****************/

.dropdown_fullwidth {
  top: 5rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  box-shadow: 0px 0px 15px #68728433;
  background-color: white;
  border-radius: 7px;
}
.dropdown_fullwidth::before {
  display: none;
}

/* .search_ads__scroller {
    max-width: 1800px;
    overflow-x: auto;
    flex-wrap: nowrap;
  } */

@media only screen and (max-width: 1535px) {
  /* .input_design {
      position: static;
    } */
  .searchmain__container {
    overflow-x: auto;
  }
  .filter_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
  .filter_dropdown_content:before {
    display: none;
  }
  .bedroom_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
  .bedroom_dropdown_content:before {
    display: none;
  }
  .budget_dropdown_content {
    top: 5rem;
    width: 18rem;
    left: 50%;
  }
  .budget_dropdown_content:before {
    display: none;
  }
  .property_type_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
  .property_type_dropdown_content:before {
    display: none;
  }
  .search_loc_input_before_search {
    position: static;
  }
  .search_loc_input_after_search {
    position: static;
  }
  .search_loc {
    position: static;
  }
  .search_localities_dropdown_content:before {
    display: none;
  }
  .search_localities_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
  .budget_dropdown_content:before {
    display: none;
  }
  .budget_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
  .home_budget_dropdown_content:before {
    display: none;
  }
  .home_budget_dropdown_content {
    top: 5rem;
    width: 100%;
    right: 1rem;
    left: 1rem;
  }
  .select_dropdown_content:before {
    display: none;
  }
  .select_dropdown_content {
    top: 5rem;
    width: auto;
    right: 1rem;
    left: 1rem;
  }
}
@media only screen and (max-width: 1400px) {
  .contentContainer {
    max-width: 58rem;
  }
  .contentAdsContainer {
    max-width: 58rem;
  }
}
@media only screen and (max-width: 992px) {
  .search_city_input {
    min-width: 15rem;
  }
  .searchmain_relative__container {
    top: 4.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .search_city_input {
    min-width: 18rem;
  }
  .searchmain_relative__container {
    top: 4rem;
  }
  .ads_img {
    max-width: 22rem;
  }
}
@media only screen and (max-width: 576px) {
  .search_city_input {
    min-width: 15rem;
  }
}
.multi_select_container {
  width: 300px;
  margin-bottom: 20px;
}

.remaining_count {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.remove_button {
  background-color: transparent;
  border: none;
  padding-left: 5px;
  cursor: pointer;
  color: #999;
  font-size: 0.9em;
  text-decoration: underline;
}
.remove_button:hover {
  color: #555;
}
.sliderLeftButtons {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(50, 61, 90, 0.12);
  border-radius: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  left: 0%;
  top: 20%;
}
.sliderRightButtons {
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(50, 61, 90, 0.12);
  border-radius: 10px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  right: 0%;
  top: 20%;
}
.furnishing_style {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  margin: 2px;
  box-sizing: border-box;
}

/* .customInfiniteScroll {} */

/* Example CSS */
.customInfiniteScroll {
  overflow: auto; /* Ensure the container can scroll */
  scrollbar-width: none; /* Hide the default scrollbar (for Firefox) */
  -ms-overflow-style: none; /* Hide the default scrollbar (for IE and Edge) */
}

.customInfiniteScroll::-webkit-scrollbar {
  width: 0; /* Hide the default scrollbar (for Webkit browsers like Chrome and Safari) */
}

@media only screen and (min-width: 1500px) {
  .search_content_container {
    padding-left: 10rem;
  }
}
